import { fetchServerData } from "./fetchServerData";

export async function getCustomAirports() {
  const fetchedData = await fetchServerData("/getcustomlocation");
  const data = customAirportRouteGeneration(fetchedData);
  return data;
}

export async function deleteCustomAirport(airportId) {
  try {
    const response = await fetchServerData("/deletecustomlocation", {
      method: "DELETE",
      body: JSON.stringify({ id: airportId }),
    });
    return response;
  } catch (error) {
    console.error("Error deleting custom airport:", error);
    throw error;
  }
}

// Calculates route distances and creates routes for custom airports
export function customAirportRouteGeneration(customAirports) {
  try {
    let routes = new Map();

    let data = {
      airports: [],
      routes: [],
    };

    for (const customAirport of customAirports) {
      const airport = customAirport.customAirport;
      data.airports.push(airport);
      const airportsWithinDistance = customAirport.connections;

      airportsWithinDistance.forEach((destination) => {
        const routeId = generateRouteId(
          airport.airportId,
          destination.airportId
        );
        // check to avoid duplicate routes and self-routes
        if (
          !routes.has(routeId) &&
          airport.airportId !== destination.airportId
        ) {
          const distance = calculateDistance(
            airport.location.coordinates[1], // latitude
            airport.location.coordinates[0], //longitude
            destination.location.coordinates[1], // latitude
            destination.location.coordinates[0] //longitude
          );

          //Set to avoid duplicate countries/regions
          let countriesSet = new Set();
          if (airport.country && airport.country.iso) {
            countriesSet.add(airport.country.iso);
          }

          if (destination.country && destination.country.iso) {
            countriesSet.add(destination.country.iso);
          }

          // creating the route object
          let routeObject = {
            _id: routeId,
            airport_1: airport.airportId,
            airport_2: destination.airportId,
            lonlat_1: [
              Number(airport.location.coordinates[0]),
              Number(airport.location.coordinates[1]),
            ],
            lonlat_2: [
              Number(destination.location.coordinates[0]),
              Number(destination.location.coordinates[1]),
            ],
            distance: Number(distance),
            countries: Array.from(countriesSet),
            type: "custom",
          };

          if (routeObject.distance <= 400) {
            // store the route
            routes.set(routeId, routeObject);
          }
        }
      });
    }

    data.routes = Array.from(routes.values());
    return data;
  } catch (error) {
    console.log("Failed to generate custom airport data: ", error);
  }
}

//Utility Functions
// function to calculate the distance between two coordinates using the Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // radius of the Earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180; // delta latitude in radians
  const dLon = ((lon2 - lon1) * Math.PI) / 180; // delta longitude in radians
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (R * c).toFixed(2); // distance in km
}

// function to generate a unique route ID based on start and end points
function generateRouteId(start, end) {
  const points = [start, end];
  points.sort(); // ensures consistency in ID regardless of order
  return points.join("_"); // concatenates points with an underscore
}
