import { useEffect, useState, useRef } from "react";
import style from "../../../css/filter.module.css";

const CountNumber = ({
  value,
  formatShort = false, // whether to display a shortened value
  stepDuration = 50, // duration (in ms) per counting step
}) => {
  const [displayValue, setDisplayValue] = useState(0);
  // Store the previous final value; default to 0 if not set.
  const prevValueRef = useRef(0);
  const timeoutId = useRef(null);

  // Helper to format numbers (e.g. 102000 -> "102K", 135256023 -> "135M")
  const formatNumber = (num) => {
    if (num >= 1e9) return Math.floor(num / 1e9) + "B";
    if (num >= 1e6) return Math.floor(num / 1e6) + "M";
    if (num >= 1e4) return Math.floor(num / 1e3) + "K";
    return Math.floor(num).toString();
  };

  useEffect(() => {
    // Clear any pending timeout if the value changes or component unmounts
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    const targetValue = Number(value);
    const startValue = prevValueRef.current || 0;
    const diff = targetValue - startValue;

    // If there's no change, just set the target immediately.
    if (diff === 0) {
      setDisplayValue(targetValue);
      return;
    }

    // Calculate a constant step: 5% of the original difference.
    const stepAmount = diff * 0.05;
    let currentValue = startValue;

    const step = () => {
      currentValue += stepAmount;

      // Clamp to target if we overshoot (works for both positive and negative steps)
      if (
        (stepAmount > 0 && currentValue >= targetValue) ||
        (stepAmount < 0 && currentValue <= targetValue)
      ) {
        currentValue = targetValue;
      }

      setDisplayValue(currentValue);

      if (currentValue !== targetValue) {
        timeoutId.current = setTimeout(step, stepDuration);
      } else {
        // Save the final value for next time.
        prevValueRef.current = currentValue;
      }
    };

    step();

    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [value, stepDuration]);

  return (
    <span className={style.resultCount} title={value.toString()}>
      {formatShort
        ? formatNumber(displayValue)
        : Math.floor(displayValue).toString()}
    </span>
  );
};

export function MenuStats({ airportsCount, routesCount, paxCount }) {
  return (
    <div className={style.results}>
      <div className={style.result}>
        <CountNumber
          value={airportsCount}
          formatShort={true}
          stepDuration={50}
        />
        <span className={style.resultName}>AIRPORTS</span>
      </div>
      <div className={style.result}>
        <CountNumber value={routesCount} formatShort={true} stepDuration={50} />
        <span className={style.resultName}>ROUTES</span>
      </div>
      {typeof paxCount === "number" && paxCount > 0 && (
        <div className={style.result}>
          <CountNumber value={paxCount} formatShort={true} stepDuration={50} />
          <span className={style.resultName}>PAX</span>
        </div>
      )}
    </div>
  );
}
