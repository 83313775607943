import { useEffect } from "react";

import { useFilterContext } from "../../ContextManager";
import { customAirportRouteGeneration } from "../../../utils/customAirportFunctions";

import { migrateCustomAirportData } from "../../../utils/legacyCustomAirportMigration";

export function MigrateCustomData() {
  const { customAirports, updateCustomAirports } = useFilterContext();

  useEffect(() => {
    const migrate = async () => {
      const response = await migrateCustomAirportData();

      if (response === null) {
        return;
      }

      const processedData = customAirportRouteGeneration(response.data);
      console.log("customAirports", customAirports);
      updateCustomAirports({
        airports: customAirports.airports.concat(processedData.airports),
        routes: customAirports.routes.concat(processedData.routes),
      });
    };

    migrate();
  }, []);

  return null;
}
