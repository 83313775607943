import { useState, useMemo, useEffect } from "react";
import { useFilterContext } from "./ContextManager";

// CSS
import style from "../css/dataView.module.css";

//Icons
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";

//Utils
import { capitalizeFirstLetter } from "../utils/utilityFunctions";
import { getComparator, stableSort } from "../utils/sortingUtils";
import {
  // groupRoutesByRatio,
  groupRoutesByDistance,
  groupRoutesByType,
  groupRoutesByTimeSaved,
  groupAirportsByCountryAndType,
  groupAirportsByRunwayLength,
  groupAirportsByCountryAndRouteCount,
} from "../utils/dataGroupingUtils";

import {
  createBarChart,
  createGroupedBarChart,
  createStackedBarChart,
} from "../utils/chartConstructors";

// Components
import { CustomPagination } from "./UX/CustomPagination/CustomPagination";
import { ExpandableTableRow } from "./UX/ExpandableRow/ExpandableRow";
import { SwitchableChart } from "./SwitchableChart";

// MUI components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function IconLabelTabs({ value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue(newValue); // Directly sets `showAirports` to 0 or 1
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label="icon label tabs example"
    >
      <Tab
        icon={<LocalAirportIcon />}
        label="Airports"
        sx={{
          fontSize: "1rem",
          fontWeight: "700",
          opacity: value === 0 ? 1 : 0.5, // Full opacity for active tab, lower opacity for inactive
        }}
      />
      <Tab
        icon={<ConnectingAirportsIcon />}
        label="Routes"
        sx={{
          fontSize: "1rem",
          fontWeight: "700",
          opacity: value === 1 ? 1 : 0.5, // Full opacity for active tab, lower opacity for inactive
        }}
      />
    </Tabs>
  );
}

function EnhancedTableToolbar({ showAirports, setShowAirports, setPage }) {
  const { updateChartIndex } = useFilterContext();

  const toggleTable = (newValue) => {
    setShowAirports(newValue); // Use the correct tab index (0 for Airports, 1 for Routes)
    setPage(0); // Reset current page to the first index
    updateChartIndex(0); // Reset charts based on selection
  };

  return (
    <Toolbar style={{ padding: "0" }}>
      <IconLabelTabs value={showAirports} setValue={toggleTable} />
    </Toolbar>
  );
}

export function DataTable() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);

  const [showAirports, setShowAirports] = useState(0); // Default to Airports
  const [rows, setRows] = useState([]);
  const [airportCharts, setAirportCharts] = useState([
    {
      title: "Airports by Type",
      type: "bar",
      options: {
        xaxis: { categories: [] },
      },
      series: [{ name: "Airports", data: [] }],
    },
  ]);

  const {
    filteredAirportsResult,
    filteredRoutesResult,
    airportIdMap,
    filters,
    airportRouteCountMap,
  } = useFilterContext();

  const rowsPerPage = 25;

  const airportHeadCells = [
    { id: "name", numeric: false, disablePadding: true, label: "Name" },
    { id: "routes", numeric: true, disablePadding: true, label: "Routes" },
    { id: "pax", numeric: true, disablePadding: true, label: "Passengers" },
    { id: "type", numeric: false, disablePadding: false, label: "Type" },
    {
      id: "municipality",
      numeric: false,
      disablePadding: false,
      label: "Municipality",
    },
    { id: "country", numeric: false, disablePadding: false, label: "Country" },
  ];

  const routeHeadCells = useMemo(() => {
    const cells = [
      { id: "name", numeric: false, disablePadding: true, label: "Route ID" },
      {
        id: "distance",
        numeric: true,
        disablePadding: true,
        label: "Distance (km)",
      },
      {
        id: "passengers",
        numeric: true,
        disablePadding: true,
        label: "Passengers",
      },
      { id: "type", numeric: false, disablePadding: false, label: "Type" },
    ];

    if (filters.timeSavedActive) {
      cells.push({
        id: "timeSaved",
        numeric: true,
        disablePadding: true,
        label: "Time Saved",
      });
    }

    return cells;
  }, [filters.timeSavedActive]);

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, showAirports } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = showAirports === 0 ? airportHeadCells : routeHeadCells;

    return (
      <TableHead>
        <TableRow>
          <TableCell
            align="center"
            padding="none"
            sx={{
              color: "var(--text-secondary)",
              fontSize: "0.875rem",
              padding: "0.3rem 0.3rem",
            }}
          ></TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ fontSize: "0.875rem" }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  fontWeight: "700",
                  fontSize: "0.9375rem",
                  whiteSpace: "nowrap", // Prevent line breaks
                }}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const createAirportData = (
    id,
    ref,
    coordinates,
    name,
    routes,
    type,
    municipality,
    country,
    countryCode,
    pax
  ) => {
    return {
      id,
      ref,
      coordinates,
      name,
      routes,
      type: capitalizeFirstLetter(type),
      municipality,
      country,
      countryCode,
      pax,
    };
  };

  const createRouteData = (id, name, distance, passengers, type, countries) => {
    return {
      id,
      name: name || "N/A",
      distance,
      passengers,
      type: capitalizeFirstLetter(type),
      countries,
    };
  };

  const airportRouteCount = (airport) => {
    if (
      airportRouteCountMap &&
      airportRouteCountMap[airport.airportId] &&
      typeof airportRouteCountMap[airport.airportId] === "number"
    ) {
      return airportRouteCountMap[airport.airportId];
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (showAirports === 0) {
      setRows(
        filteredAirportsResult.map((airport) =>
          createAirportData(
            airport.airportId ? airport.airportId : null,
            airport.airportRef ? airport.airportRef : null,
            airport.location ? airport.location.coordinates : null,
            airport.name ? airport.name : "N/A",
            airportRouteCount(airport),
            airport.type ? airport.type : "N/A",
            airport.municipality ? airport.municipality : "N/A",
            airport.country ? airport.country.name : "N/A",
            airport.country ? airport.country.iso : null,
            airport.pax ? airport.pax : null
          )
        )
      );
    } else if (showAirports === 1) {
      setRows(
        filteredRoutesResult.map((route) => {
          const routeName =
            route.airport_1 && route.airport_2
              ? `${airportIdMap.get(route.airport_1).name} - ${
                  airportIdMap.get(route.airport_2).name
                }`
              : "N/A";
          let rowData = createRouteData(
            route._id ? route._id : null,
            routeName,
            route.distance ? Math.ceil(route.distance) : "N/A",
            route.pax ? route.pax : null,
            route.type ? route.type : "N/A",
            route.countries ? route.countries : null
          );

          if (filters.timeSavedActive) {
            rowData.timeSaved = route.timeSaved;
          }

          return rowData;
        })
      );
    }
  }, [
    showAirports,
    filteredAirportsResult,
    filteredRoutesResult,
    airportIdMap,
    filters.timeSavedActive,
    airportRouteCountMap,
  ]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  useEffect(() => {
    const totalPages = Math.ceil(rows.length / rowsPerPage);
    if (page > totalPages) setPage(totalPages - 1);
  }, [rows]);

  // Calculate empty rows
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - rows.length);

  // Utility to count airport types
  const countAirportTypes = (airports, types) => {
    const counts = types.reduce((acc, type) => {
      acc[type] = 0; // Initialize each type count to 0
      return acc;
    }, {});

    airports.forEach((airport) => {
      if (counts[airport.type] !== undefined) {
        counts[airport.type] += 1; // Increment count if type exists in filters.type
      }
    });

    return Object.values(counts); // Return only the counts
  };

  const listAirportTypes = () => {
    return filters.type.filter((type) => type !== "custom"); // List only valid types
  };

  useEffect(() => {
    const types = listAirportTypes(); // Get airport types from filters
    const capitalizedTypes = types.map(capitalizeFirstLetter);

    // "Airports by Type" chart remains the same
    const airportTypeCounts = countAirportTypes(filteredAirportsResult, types);
    const airportsByTypeChart = createBarChart({
      title: "Airports by Type",
      categories: capitalizedTypes,
      seriesData: airportTypeCounts,
      dataLabelsConfig: {
        enabled: true,
        formatter: (val) => val,
        offsetY: -20,
        style: { fontSize: "12px", colors: ["#304758"] },
      },
    });

    // Prepare data for "Airports by Country and Type" stacked chart
    const typeCountries = [];
    const typeSeries = types.map((type) => ({
      name: capitalizeFirstLetter(type),
      data: [],
    }));

    // Generate counts for each country and type using the grouping function
    const typeCountryCounts = groupAirportsByCountryAndType(
      filteredAirportsResult,
      types
    );

    // Populate `countries` and `typeSeries` using `countryCounts`
    typeCountryCounts.forEach(({ country, typeCounts }) => {
      typeCountries.push(country);
      typeSeries.forEach((series, index) => {
        series.data.push(typeCounts[index] || 0); // Ensure each stack has data for each type
      });
    });

    // New chart: Airports by Country and Route Count
    const routeCountGroups = [
      { min: 0, max: 10, label: "0-10 Routes" },
      { min: 11, max: 30, label: "11-30 Routes" },
      { min: 31, max: 50, label: "31-50 Routes" },
      { min: 51, max: 75, label: "51-75 Routes" },
      { min: 76, max: Infinity, label: "76+ Routes" },
    ];
    const routeCountGroupLabels = routeCountGroups.map((g) => g.label);

    const routeCountCountries = [];
    const routeCountGroupSeries = routeCountGroupLabels.map((label) => ({
      name: label,
      data: [],
    }));

    const countryGroupCounts = groupAirportsByCountryAndRouteCount(
      filteredAirportsResult,
      airportRouteCountMap
    );

    countryGroupCounts.forEach(({ country, groupCounts }) => {
      routeCountCountries.push(country);
      routeCountGroupSeries.forEach((series, index) => {
        series.data.push(groupCounts[index] || 0);
      });
    });

    // Create stacked bar chart for "Airports by Country and Type"
    const airportsByCountryAndTypeChart = createStackedBarChart({
      title: "Airports by Country and Type",
      categories: typeCountries,
      seriesData: typeSeries,
    });

    const airportsByCountryAndRouteCountChart = createStackedBarChart({
      title: "Airports by Country and Route Count",
      categories: routeCountCountries,
      seriesData: routeCountGroupSeries,
    });

    // New grouped bar chart: "Airports by Runway Requirement per Country"
    const { categories: runwayLength, seriesData: runwaySeriesData } =
      groupAirportsByRunwayLength(filteredAirportsResult);

    const airportsByRunwayLengthChart = createGroupedBarChart({
      title: "Airports by Runway Requirement",
      categories: runwayLength,
      seriesData: runwaySeriesData,
    });

    setAirportCharts([
      airportsByTypeChart,
      airportsByCountryAndTypeChart,
      airportsByRunwayLengthChart,
      airportsByCountryAndRouteCountChart,
    ]);
  }, [filteredAirportsResult, filters.type, airportRouteCountMap]);

  const routeCharts = useMemo(() => {
    const distanceData = groupRoutesByDistance(filteredRoutesResult);
    const routeTypeData = groupRoutesByType(filteredRoutesResult);
    // const ratioData = groupRoutesByRatio(filteredRoutesResult);

    const routeDistanceChart = createBarChart({
      title: "Routes Distance",
      categories: distanceData.map((d) => d.range),
      seriesData: distanceData.map((d) => d.count),
    });

    const routeTypeChart = createBarChart({
      title: "Route Type",
      categories: routeTypeData.categories,
      seriesData: routeTypeData.counts,
    });

    // const ratioChart = createBarChart({
    //   title: "Ratio",
    //   categories: ratioData.ratioRanges,
    //   seriesData: ratioData.ratioCountsArray,
    // });

    // Initialize charts array with mandatory charts
    const charts = [routeDistanceChart, routeTypeChart];

    // Conditionally add the Time Saved chart
    if (filters.timeSavedActive) {
      const timeSavedData = groupRoutesByTimeSaved(filteredRoutesResult);

      const timeSavedChart = createBarChart({
        title: "Time Saved",
        categories: timeSavedData.map((t) => t.range),
        seriesData: timeSavedData.map((t) => t.count),
      });

      // Insert the Time Saved chart into the charts array
      charts.splice(1, 0, timeSavedChart); // Inserts at index 1
    }

    return charts;
  }, [filteredRoutesResult, filters.timeSavedActive]);
  return (
    <div className={style.wrapper}>
      <SwitchableChart
        charts={showAirports === 0 ? airportCharts : routeCharts}
      />

      <div className={style.tableWrapper}>
        <EnhancedTableToolbar
          showAirports={showAirports}
          setShowAirports={setShowAirports}
          setPage={setPage}
        />
        <TableContainer className={style.tableContainer}>
          <Table
            className={style.table}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              headCells={showAirports === 0 ? airportHeadCells : routeHeadCells} // Explicitly check for 0 or 1
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              showAirports={showAirports}
            />
            <TableBody>
              {visibleRows.map((row, index) => (
                <ExpandableTableRow
                  key={row.id}
                  row={row}
                  labelId={`enhanced-table-checkbox-${index}`}
                  showAirports={showAirports}
                  rowNumber={page * rowsPerPage + index + 1} // Calculate row number
                  headCells={
                    showAirports === 0 ? airportHeadCells : routeHeadCells
                  } // Pass headCells
                />
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomPagination
          rowsPerPage={rowsPerPage}
          totalRows={rows.length}
          currentPage={page}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
