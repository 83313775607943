import { useState } from "react";
import { useFilterContext } from "./ContextManager";
import { fetchServerData } from "../utils/fetchServerData";
import { customAirportRouteGeneration } from "../utils/customAirportFunctions";

// MUI Components
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

// MUI Select will be used to pick custom airport groups
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';

import style from "../css/customAirportForm.module.css";

export function CustomAirportForm({ coordinates }) {
  const {
    updateCustomAirportForm,
    editingAirport,
    updateEditingAirport,
    customAirports,
    updateCustomAirports,
    selectedAirport,
    onSelectAirport,
    selectedRoute,
    onSelectRoute,
  } = useFilterContext();

  const [name, setName] = useState(editingAirport ? editingAirport.name : "");
  const [description, setDescription] = useState(
    editingAirport ? editingAirport.description : ""
  );
  const [group, setGroup] = useState("Default"); // Will be used when grouping of custom airports are implemented
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // State for error message

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSubmitting) return;
    setIsSubmitting(true);
    setErrorMessage("");

    // Build the payload with common fields
    const payload = {
      name: name.trim(),
      group,
      description: description.trim(),
    };

    // If this is a new airport creation (not editing), include coordinates.
    if (!editingAirport && coordinates && coordinates.lat && coordinates.lon) {
      payload.lat = coordinates.lat;
      payload.lon = coordinates.lon;
    }

    try {
      let response;
      if (editingAirport) {
        // For editing, add the airport id and call the update endpoint
        payload._id = editingAirport._id;
        response = await fetchServerData("/updatecustomlocation", {
          method: "PUT",
          body: JSON.stringify(payload),
        });

        updateCustomAirports((prev) => {
          const updatedAirports = prev.airports.map((airport) =>
            airport._id === payload._id ? { ...airport, ...payload } : airport
          );
          // Also, update the routes if needed.
          // For example, recalculate the routes for the updated airport:

          return {
            airports: updatedAirports,
            routes: prev.routes,
          };
        });
      } else {
        // For new airport creation
        response = await fetchServerData("/storecustomlocation", {
          method: "POST",
          body: JSON.stringify({
            ...payload,
            lat: coordinates.lat,
            lon: coordinates.lon,
          }),
        });
        const processedData = customAirportRouteGeneration([response.data]);
        updateCustomAirports({
          airports: customAirports.airports.concat(processedData.airports),
          routes: customAirports.routes.concat(processedData.routes),
        });

        if (selectedAirport) {
          onSelectAirport(null);
        }

        if (selectedRoute) {
          onSelectRoute(null);
        }
      }

      updateCustomAirportForm(false);
      updateEditingAirport(null);
    } catch (error) {
      console.error(
        editingAirport
          ? "Failed to update custom airport:"
          : "Failed to add custom airport:",
        error
      );
      setErrorMessage(
        editingAirport
          ? "Failed to update custom airport. Please try again."
          : "Failed to add custom airport. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelCustomAirport = () => {
    updateCustomAirportForm(false);
    updateEditingAirport(null);
  };

  return (
    <>
      <div className={style.formWrapper}>
        <form className={style.customForm} onSubmit={handleSubmit}>
          <div className={style.titleErrorWrapper}>
            <p className={style.title}>
              {editingAirport ? "Edit Custom Airport" : "Custom Airport"}
            </p>{" "}
            {errorMessage && (
              <p className={style.errorMessage}>{errorMessage}</p>
            )}{" "}
            {/* Display error message */}
          </div>

          <TextField
            label="Name"
            autoComplete="off"
            size="small"
            value={name}
            onChange={handleNameChange}
            disabled={isSubmitting}
            inputProps={{ maxLength: 35 }}
            fullWidth
            variant="outlined"
            required
          />
          <TextField
            label="Description"
            autoComplete="off"
            multiline
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
            disabled={isSubmitting}
            inputProps={{ maxLength: 350 }}
            fullWidth
            variant="outlined"
          />
          <div className={style.buttonWrapper}>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : editingAirport ? (
                "Update"
              ) : (
                "Add"
              )}
            </Button>
            <Button onClick={cancelCustomAirport} disabled={isSubmitting}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
