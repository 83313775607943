import { useState, useEffect } from "react";
import { useFilterContext } from "./ContextManager";
import style from "../css/loadingSpinnerModal.module.css";
import CircularProgress from "@mui/material/CircularProgress";

export function LoadingSpinnerModal() {
  const { loadingState, legacyCustomAirportLoading } = useFilterContext();
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    let timer;
    if (loadingState || legacyCustomAirportLoading) {
      // Set a timer to show the spinner only after 500ms
      timer = setTimeout(() => {
        setShowSpinner(true);
      }, 1000); // Delay in ms
    } else {
      // If loading completes, hide the spinner and clear the timer
      setShowSpinner(false);
      clearTimeout(timer);
    }

    // Cleanup function to clear the timer if the component unmounts or loadingState changes
    return () => clearTimeout(timer);
  }, [loadingState, legacyCustomAirportLoading]); // Dependency array includes loadingState

  return showSpinner ? (
    <div className={`${style.loadingModal} ${style.flexColumnCenter}`}>
      <div className={style.flexColumnCenter}>
        <CircularProgress />
        <p>Fetching data...</p>
      </div>
    </div>
  ) : null;
}
