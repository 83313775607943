const baseURL = "https://neadev-dot-erudite-imprint-406809.ey.r.appspot.com/";

export const submitFeedback = async (feedback) => {
  try {
    const token = await window.Clerk.session.getToken();
    const response = await fetch(`${baseURL}/feedback`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(feedback),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
    throw error;
  }
};
