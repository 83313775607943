// The URL to your endpoint
const baseURL = "https://nea-dot-erudite-imprint-406809.ey.r.appspot.com";

export async function fetchServerData(endpoint, options = {}) {
  try {
    // Get the session token
    const token = await window.Clerk.session.getToken();
    const url =
      baseURL + (endpoint.startsWith("/") ? endpoint : "/" + endpoint);
    // console.log("Fetching from:", url);

    // Default options for every request
    const defaultOptions = {
      credentials: "include", // Required for Clerk backend auth
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // Merge default options with any provided options (e.g., method, body)
    const mergedOptions = { ...defaultOptions, ...options };

    const response = await fetch(url, mergedOptions);
    // console.log("Response status:", response.status);

    if (!response.ok) {
      console.log("Response details:", {
        status: response.status,
        url: url,
        endpoint: endpoint,
      });
      throw new Error(`Network response was not ok: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Fetch error details:", {
      message: error.message,
      endpoint: endpoint,
      fullUrl: baseURL + endpoint,
    });
    throw error;
  }
}
