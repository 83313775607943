import light from "../assets/button/button_lightmode.png";
import dark from "../assets/button/button_darkmode.png";
import google from "../assets/button/button_standard.png";
import satellite from "../assets/button/button_satellite.png";

export const mapStyles = {
  light: {
    id: "5a313eaac0c2c586",
    name: "Light",
    colorIndex: 0,
    preview: light,
    type: "styled",
  },
  dark: {
    id: "ffe3b9bd26b0c434",
    name: "Dark",
    colorIndex: 1,
    preview: dark,
    type: "styled",
  },
  standard: {
    id: "887f18fceafe25fd",
    name: "Standard",
    colorIndex: 2,
    preview: google,
    type: "styled",
  },
  hybrid: {
    id: null,
    name: "Hybrid",
    colorIndex: 3,
    preview: satellite,
    type: "hybrid",
  },
};
