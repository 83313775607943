import { useState, useRef, useEffect } from "react";
import { useFilterContext } from "../../ContextManager";

// CSS
import style from "../../../css/dataView.module.css";

// MUI
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

export function ExpandableTableRow({
  row,
  labelId,
  showAirports,
  rowNumber,
  headCells,
}) {
  // const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  const { onSelectAirport, onSelectRoute, selectedAirport, filters } =
    useFilterContext();

  const isExpanded = false;

  useEffect(() => {
    let timeoutId;
    if (isExpanded) {
      setIsVisible(true);
      timeoutId = setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        }
      }, 50);
    } else {
      if (contentRef.current) {
        contentRef.current.style.maxHeight = "0px";
      }
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 300);
    }
    return () => clearTimeout(timeoutId);
  }, [isExpanded]);

  // const toggleExpansion = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const handleClick = () => {
    if (showAirports === 0 && row.id) {
      onSelectAirport({
        ...selectedAirport,
        id: row.id,
        coordinates: row.coordinates,
      });
      onSelectRoute(null);
    }
    if (showAirports === 1 && row.id) {
      onSelectRoute(row.id);
    }
  };

  const totalColumns = headCells.length + 1; // +1 for the row number column

  return (
    <>
      <TableRow
        hover
        // onClick={toggleExpansion}
        onClick={handleClick}
        role="button"
        aria-expanded={isExpanded}
        aria-controls={`details-${row.id}`}
        tabIndex={-1}
        key={row.id}
        className={style.tableRow}
        sx={{
          "td, th": {
            borderBottom: isExpanded
              ? "none"
              : "1px solid rgba(224, 224, 224, 1)", // Conditionally hide bottom border
          },
        }}
      >
        {/* Add the row number */}
        <TableCell
          className={style.rowNumber}
          align="right"
          padding="none"
          sx={{
            color: "var(--text-secondary)",
            fontSize: "0.875rem",
            padding: "0.25rem 0.5rem",
            opacity: "0.65",
          }} // Apply light grey color and smaller font size
        >
          {rowNumber}
        </TableCell>
        {showAirports === 0 ? ( // Change from boolean to explicit check for 0 (Airports)
          <>
            <TableCell component="th" id={labelId} scope="row" padding="none">
              <div className={style.truncatedText}>
                <Typography
                  noWrap
                  className={style.truncatedText}
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    whiteSpace: "nowrap", // Prevent line breaks
                  }}
                >
                  {row.name}
                </Typography>
              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              {row.routes}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              {typeof row.pax === "number" ? row.pax.toLocaleString() : null}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              {row.type}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              {row.municipality}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              <div className={style.airportCountryAndNameWrapper}>
                {" "}
                {row.countryCode && row.countryCode.toUpperCase() !== "XX" && (
                  <img
                    loading="lazy"
                    height="12"
                    src={`https://flagcdn.com/h20/${row.countryCode
                      .split("-")[0]
                      .toLowerCase()}.webp`}
                    alt={`Flag of ${row.countryCode}`}
                    className={style.countryFlag}
                  />
                )}
                {row.country}
              </div>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell component="th" id={labelId} scope="row" padding="none">
              <div className={style.truncatedText}>
                <Typography
                  noWrap
                  className={style.truncatedText}
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    whiteSpace: "nowrap", // Prevent line breaks
                  }}
                >
                  {row.name}
                </Typography>
              </div>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              {row.distance}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                whiteSpace: "nowrap", // Prevent line breaks
              }}
            >
              {/* check if toLocaleString can be used */}
              {typeof row.passengers === "number"
                ? row.passengers.toLocaleString()
                : null}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                fontSize: "0.875rem",
                fontWeight: "400",
                minWidth: "6.5rem",
                // display: "flex",
              }}
            >
              <div className={style.routeCountryNameAndFlagWrapper}>
                {row.type}
                {row.countries && (
                  <div className={style.routeCountryFlagWrapper}>
                    {row.countries.length > 1 ? (
                      <>
                        {row.countries[0].split("-")[0].toUpperCase() !==
                          "XX" && (
                          <img
                            loading="lazy"
                            height="12"
                            src={`https://flagcdn.com/h20/${row.countries[0]
                              .split("-")[0]
                              .toLowerCase()}.webp`}
                            alt={`Flag of ${row.countries[0]}`}
                            className={style.countryFlag}
                          />
                        )}
                        {row.countries[1].split("-")[0].toUpperCase() !==
                          "XX" && (
                          <img
                            loading="lazy"
                            height="12"
                            src={`https://flagcdn.com/h20/${row.countries[1]
                              .split("-")[0]
                              .toLowerCase()}.webp`}
                            alt={`Flag of ${row.countries[1]}`}
                            className={style.countryFlag}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {row.countries[0].split("-")[0].toUpperCase() !==
                          "XX" && (
                          <img
                            loading="lazy"
                            height="12"
                            src={`https://flagcdn.com/h20/${row.countries[0]
                              .split("-")[0]
                              .toLowerCase()}.webp`}
                            alt={`Flag of ${row.countries[0]}`}
                            className={style.countryFlag}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </TableCell>
            {/* <TableCell
              align="right"
              sx={{ fontSize: "0.875rem", fontWeight: "400" }}
            >
              {row.ratio}
            </TableCell> */}
            {filters.timeSavedActive && (
              <TableCell
                align="right"
                sx={{ fontSize: "0.875rem", fontWeight: "400" }}
              >
                {row.timeSaved}
              </TableCell>
            )}
          </>
        )}
      </TableRow>
      {isVisible && (
        <TableRow
          id={`details-${row.id}`}
          role="region"
          className={style.expandedRow}
        >
          <TableCell
            colSpan={totalColumns} // Adjust to match the new total column count including the row number column
            className={`${style.expandedCell} ${
              isExpanded && isVisible ? style.visible : ""
            }`}
          >
            <div ref={contentRef} className={style.expandedContent}>
              {showAirports === 0 ? ( // Change to explicit check for Airports
                <>
                  <h3>Additional details for {row.name}</h3>
                  <p>Airport ID: {row.airportId}</p>
                  <p>Type: {row.type}</p>
                  <p>Municipality: {row.municipality}</p>
                  <p>Country: {row.country}</p>
                </>
              ) : (
                <h3>Additional details for Route ID: {row._id}</h3>
              )}
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
