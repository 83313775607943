import { fetchServerData } from "./fetchServerData";

const DB_NAME = "Custom_Airports";
const STORE_NAME = "Airports";

// Open the IndexedDB database without creating new object stores
function openDb() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME);
    request.onerror = (event) => {
      console.error("Database error:", event.target.errorCode);
      reject("Database error: " + event.target.errorCode);
    };
    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
    request.onupgradeneeded = (event) => {
      // Do not create any object store here.
      // A new or upgraded database will be empty – no store means no data to migrate.
      console.log("Database is new or upgraded; no data to migrate.");
    };
  });
}

// Retrieve all records from the object store
async function getAllAirports(db) {
  return new Promise((resolve, reject) => {
    try {
      const transaction = db.transaction([STORE_NAME], "readonly");
      const store = transaction.objectStore(STORE_NAME);
      const request = store.getAll();
      request.onsuccess = () => resolve(request.result);
      request.onerror = (event) =>
        reject("Error fetching all airports: " + event.target.errorCode);
    } catch (error) {
      reject("Store does not exist.");
    }
  });
}

async function remoteStoreCustomAirports(db) {
  try {
    const airports = await getAllAirports(db);

    if (!Array.isArray(airports) || airports.length === 0) {
      return null;
    }

    const requestData = airports.map((airportObject) => {
      const airport = airportObject.airport;
      const processedData = {
        name: airport.name.trim(),
        group: "Default",
        description: airport.description.trim(),
        lat: airport.location.coordinates[1],
        lon: airport.location.coordinates[0],
      };
      return processedData;
    });

    const response = await fetchServerData("/storecustomlocation", {
      method: "POST",
      body: JSON.stringify(requestData),
    });
    return response;
  } catch (error) {
    console.error("Remote storage error:", error);
  }
}

// Delete the entire IndexedDB database
async function deleteDatabase() {
  return new Promise((resolve, reject) => {
    const deleteRequest = indexedDB.deleteDatabase(DB_NAME);
    deleteRequest.onsuccess = () => {
      console.log("Database deleted successfully.");
      resolve();
    };
    deleteRequest.onerror = (event) => {
      console.error("Error deleting database:", event);
      reject(event);
    };
    deleteRequest.onblocked = (event) => {
      console.warn("Database deletion blocked:", event);
    };
  });
}

async function doesDatabaseExist(dbName) {
  // indexedDb.databases() is currently experimental and only available in newer browsers. This is just a check to see if the method is available
  if (indexedDB.databases) {
    const databases = await indexedDB.databases();
    return databases.some((db) => db.name === dbName);
  }
  // Fallback if indexedDB.databases() is unavailable:
  // Attempt to open the database and check for upgrade events.
  return new Promise((resolve) => {
    const req = indexedDB.open(dbName);
    let existed = true;
    req.onupgradeneeded = () => {
      existed = false;
    };
    req.onsuccess = () => {
      req.result.close();
      // Delete the created database if it didn't exist before
      if (!existed) {
        indexedDB.deleteDatabase(dbName);
      }
      resolve(existed);
    };
    req.onerror = () => {
      resolve(false);
    };
  });
}

// Main migration function
export async function migrateCustomAirportData() {
  try {
    const exists = await doesDatabaseExist(DB_NAME);
    if (!exists) {
      console.log("Database does not exist, nothing to migrate.");
      return null;
    }

    const db = await openDb();

    // If the expected store doesn't exist, there's nothing to migrate.
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      console.log("Object store not found. Deleting database.");
      db.close();
      await deleteDatabase();
      return null;
    }

    // Retrieve data from indexedDB and store it remotely
    const requestData = await remoteStoreCustomAirports(db);

    console.log("Data migrated to MongoDB.");

    // Close the database connection and then delete the database.
    db.close();

    // Only delete if remote storage was successful.
    if (requestData && requestData.success === true) {
      await deleteDatabase();
      return requestData;
    } else if (requestData === null) {
      await deleteDatabase();
      return null;
    }
  } catch (error) {
    console.error("Migration error:", error);
  }
}
