import { createContext, useContext, useState } from "react";
const filterContext = createContext();

export function FilterContextProvider(properties) {
  const { children } = properties;

  const [filters, setFilters] = useState({});

  const [airportsData, setAirportsData] = useState(null);

  const [routesData, setRoutesData] = useState(null);

  const [filteredAirportsResult, setFilteredAirportsResult] = useState([]);

  const [filteredRoutesResult, setFilteredRoutesResult] = useState([]);

  const [selectedAirport, setSelectedAirport] = useState(null);

  const [modalOpenState, setModalOpenState] = useState(false);

  const [airportIdMap, setAirportIdMap] = useState(null);

  const [lastMapCenter, setLastMapCenter] = useState(null);

  const [lastMapZoom, setLastMapZoom] = useState(null);

  const [apiLoader, setApiLoader] = useState(null);

  const [routeModalOpenState, setRouteModalOpenState] = useState(false);

  const [selectedRoute, setSelectedRoute] = useState(null);

  const [loadingState, setLoadingState] = useState(false);

  const [customAirportForm, setCustomAirportForm] = useState(false);

  const [editingAirport, setEditingAirport] = useState(null);

  const [restoreDefaultCount, setRestoreDefaultCount] = useState(0);

  const [chartIndex, setChartIndex] = useState(0);

  const [searchBarIsOpen, setSearchBarIsOpen] = useState(false);

  const [airportRouteCountMap, setAirportRouteCountMap] = useState({});

  const [selectedProfile, setSelectedProfile] = useState("default");

  const [customAirports, setCustomAirports] = useState({
    airports: [],
    routes: [],
  });

  const [paxCount, setPaxCount] = useState(null);

  const [legacyCustomAirportLoading, setLegacyCustomAirportLoading] =
    useState(false);

  //functions that is used in other components to update the corresponding useState
  function updateFilters(filters) {
    setFilters(filters);
  }

  function setFilteredAirportsData(data) {
    setFilteredAirportsResult(data);
  }
  function setFilteredRoutesData(data) {
    setFilteredRoutesResult(data);
  }

  function onSelectAirport(airport) {
    setSelectedAirport(airport);
  }

  function updateModalState(state) {
    setModalOpenState(state);
    if (state) {
      setRouteModalOpenState(false); // closes the route modal if the airport modal is opened
    }
  }

  function updateAirportIdMap(map) {
    setAirportIdMap(map);
  }

  function updateLastMapCenter(center) {
    setLastMapCenter(center);
  }

  function updateLastMapZoom(zoom) {
    setLastMapZoom(zoom);
  }

  function updateApiLoader(loader) {
    setApiLoader(loader);
  }

  function onSelectRoute(route) {
    setSelectedRoute(route);
  }

  function updateRouteModalState(state) {
    setRouteModalOpenState(state);
    if (state) {
      setModalOpenState(false); // closes the airport modal if the route modal is opened
    }
  }

  function updateLoadingState(state) {
    setLoadingState(state);
  }

  function updateCustomAirportForm(state) {
    setCustomAirportForm(state);
  }

  function updateEditingAirport(airport) {
    setEditingAirport(airport);
  }

  function updateRestoreDefaultCount(count) {
    setRestoreDefaultCount(count);
  }

  function updateChartIndex(index) {
    setChartIndex(index);
  }

  function updateSearchBarIsOpen(state) {
    setSearchBarIsOpen(state);
  }

  function updateAirportRouteCountMap(data) {
    setAirportRouteCountMap(data);
  }

  function updateSelectedProfile(profile) {
    setSelectedProfile(profile);
  }

  function updateCustomAirports(object) {
    setCustomAirports(object);
  }

  function updatePaxCount(number) {
    setPaxCount(number);
  }

  function updateLegacyCustomAirportLoading(boolean) {
    setLegacyCustomAirportLoading(boolean);
  }

  //which functions and useStates are used by the context provider
  const value = {
    filters,
    updateFilters,
    filteredAirportsResult: filteredAirportsResult,
    setFilteredAirportsData: setFilteredAirportsData,
    filteredRoutesResult: filteredRoutesResult,
    setFilteredRoutesData: setFilteredRoutesData,
    selectedAirport,
    onSelectAirport,
    airportsData,
    setAirportsData,
    routesData,
    setRoutesData,
    modalOpenState,
    updateModalState,
    airportIdMap,
    updateAirportIdMap,
    lastMapCenter,
    updateLastMapCenter,
    lastMapZoom,
    updateLastMapZoom,
    apiLoader,
    updateApiLoader,
    selectedRoute,
    onSelectRoute,
    routeModalOpenState,
    updateRouteModalState,
    loadingState,
    updateLoadingState,
    customAirportForm,
    updateCustomAirportForm,
    editingAirport,
    updateEditingAirport,
    restoreDefaultCount,
    updateRestoreDefaultCount,
    chartIndex,
    updateChartIndex,
    searchBarIsOpen,
    updateSearchBarIsOpen,
    airportRouteCountMap,
    updateAirportRouteCountMap,
    selectedProfile,
    updateSelectedProfile,
    customAirports,
    updateCustomAirports,
    paxCount,
    updatePaxCount,
    legacyCustomAirportLoading,
    updateLegacyCustomAirportLoading,
  };

  return (
    <filterContext.Provider value={value}>{children}</filterContext.Provider>
  );
}

//a callback function for other components to deconstruct into values
export function useFilterContext() {
  return useContext(filterContext);
}
