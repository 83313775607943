import { useEffect, useState } from "react";
import { useFilterContext } from "./ContextManager";
import { TruncatedText } from "./UX/TruncatedText/TruncatedText";
import { deleteCustomAirport } from "../utils/customAirportFunctions";

import { delay } from "../utils/utilityFunctions";

//MUI components
import IconButton from "@mui/material/IconButton";

//icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import style from "../css/customAirportList.module.css";

export function CustomAirportList() {
  const {
    updateCustomAirportForm,
    updateEditingAirport,
    customAirports,
    updateCustomAirports,
  } = useFilterContext();

  const [currentCustomAirports, setCurrentCustomAirports] = useState([]);

  useEffect(() => {
    if (
      customAirports &&
      customAirports.airports &&
      customAirports.airports.length > 0
    ) {
      const sortedAirports = [...customAirports.airports].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      const reducedResults = sortedAirports.map((airport) => ({
        _id: airport._id,
        name: airport.name,
        description: airport.description,
        group: airport.group,
      }));
      setCurrentCustomAirports(reducedResults);
    } else {
      // If no airports, update state to an empty array.
      setCurrentCustomAirports([]);
    }
  }, [customAirports]);

  const handleEdit = async (airport) => {
    // Set the data for the form and open it
    updateCustomAirportForm(false);
    updateEditingAirport(airport); // Store airport in context so it can be used by the form
    await delay(100);
    updateCustomAirportForm(true); // Show CustomAirportForm
  };

  const handleDelete = async (airportId) => {
    try {
      const result = await deleteCustomAirport(airportId);
      if (result.success) {
        // Update the context state to "locally" remove the deleted airport
        updateCustomAirports((prev) => ({
          airports: prev.airports.filter(
            (airport) => airport._id !== airportId
          ),
          routes: prev.routes.filter(
            (route) =>
              route.airport_1 !== airportId && route.airport_2 !== airportId
          ),
        }));
      }
    } catch (error) {
      console.error("Error deleting airport:", error);
    }
  };

  return (
    <>
      {currentCustomAirports.length > 0 ? (
        <div className={style.wrapper}>
          {currentCustomAirports.map((airport, i) => (
            <div key={airport._id}>
              <div className={style.airportMain}>
                <TruncatedText
                  text={airport.name}
                  maxWidthPx={200}
                  elementClass={style.name}
                />
                <div className={style.icons}>
                  <IconButton onClick={() => handleEdit(airport)}>
                    <EditIcon className={style.icon} />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(airport._id)}>
                    <DeleteIcon className={style.icon} />
                  </IconButton>
                </div>
              </div>
              {i !== currentCustomAirports.length - 1 && (
                <div className={style.line}></div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p className={style.emptyListMessage}>
          Right-click on the map to add your own custom airports.
        </p>
      )}
    </>
  );
}
